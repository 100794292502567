export class JoinMessageConstant {

  public static ITEM_EQUIRED: string = '필수입력 항목';
  public static VALID_CHECK_EQUIRED: string = '유효성 체크 필요 항목';
  public static JOIN_STEP_ERROR: string = '정상적인 접근 방식이 아닙니다. 홈으로 이동합니다.';

  public static PASSWORD_CHEAK_REG: RegExp = /^[A-Za-z0-9!~@#$%^&*()?+\-=_.\/]{10,20}$/;
  public static PASSWORD_SPECIAL_CHARACTERS: string = '!~@#$%^&*()?+-=_.';
  public static PASSWORD_BLANK_DENIED: string = '공백문자 불가';
  public static PASSWORD_LENGTH_DENIED: string = ' 최소 10자 이상, 최대 20자 이하 입력';
  public static PASSWORD_NON_ALLOWED_CHARACTERS: string = '영문,숫자,특수문자' + JoinMessageConstant.PASSWORD_SPECIAL_CHARACTERS + ' 외 불가';
  public static PASSWORD_NUM_INCLUSION_REQUIRED: string = '1개 이상의 숫자 포함 필수';
  public static PASSWORD_ENG_INCLUSION_REQUIRED: string = '1개 이상의 영문 포함 필수';
  public static PASSWORD_SPE_INCLUSION_REQUIRED: string = '특수문자 ' + JoinMessageConstant.PASSWORD_SPECIAL_CHARACTERS + ' 포함 필수';
  public static PASSWORD_TWO_ARE_DIFFERENT: string = '비밀번호와 동일한 입력 필수';
  public static PASSWORD_MISMATCH: string = '비밀번호가 일치하지 않음';


  public static ID_CHEAK_REG: RegExp = /^[A-Za-z0-9@\-_.]{4,20}$/;
  public static ID_DUPLICATED_CHECH_ERROR: string = '아이디 중복 체크 오류';
  public static ID_DUPLICATED: string = '사용불가 (중복 아이디)';

  public static EMAIL_CHECK_REG: RegExp = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;
  public static EMAIL_ACCURATE_EQUIRED: string = '정확한 이메일 주소 입력 필수';

  public static COMPANY_NUMBER_ACCURATE_EQUIRED: string = '정확한 사업자 번호 입력 필수';
  public static COMPANY_NUMBER_CHECK_ERROR: string = '사업자 번호 확인 필요 (폐업/휴업 사업자 불가)';
  public static BANK_INFO_EQUIRED: string = '금융기관 선택 필수';
  public static BANK_ACCOUNT_NO_MIN_LENGTH: string = '정확한 계좌 정보 입력 필수';
  public static BANK_ACCOUNT_ONLY_NUMBER: string = '숫자만 입력';


  public static NAME_LENGTH_DENIED: string = '최소 2자 이상, 최대 20자 이하 입력';
  public static NAME_BLANK_DENIED: string = '공백문자 불가';


  public static PHONE_LENGTH_DENIED: string = '정확한 연락처 입력 필수';
  public static PHONE_BLANK_DENIED: string = '공백문자 불가';
}
