export class CollectionWastePhotoModel {
  public COLLECTION_WASTE_PHOTO_ID: string = '';
  public TRANSACTION_NO: string = '';
  public COLLECTION_NO: string = '';
  public LOADING_UNLOADING_YN: string = '';
  public COLLECTION_PHOTO_TP: string = '';
  public WASTE_TP: string = '';
  public ITEM_CD: string = '';
  public WASTE_FILE_GROUP_ID: string = '';
  public POSITION_Y: string = '';
  public POSITION_X: string = '';
  public POSITION_ADDRESS: string = '';
  public CREATED_DT: string = '';
  public CREATED_TIMES: number = 0;


  public FILE_ID: string = '';
  public FILE_CONTENT_TYPE: string = '';
  public DISP_ORDER: string = '';
  public FILE_NAME: string = '';
  public FILE_ORIGIN_EXT: string = '';
  public FILE_ORIGIN_NAME: string = '';
  public FILE_PATH: string = '';
  public FILE_SIZE: number = 0;

}
